@font-face {
  font-family: "Edwardian";
  src: url('http://davidharroch.fr/charlestraiteur/fonts/Edwardian/Edwardian-Script-ITC_15099.ttf');
}
$fontEd: "Edwardian";

@font-face {
  font-family: "Calibri";
  src: url('http://davidharroch.fr/charlestraiteur/fonts/Calibri/CalibriRegular.ttf');
}
$fontCalibri: "Calibri";

html, body {
  position: relative;
  font-size: calc(16px + 0.5vw);
	scroll-behavior: smooth;

	@include bp(1024) {
	  font-size: .8vw;
	}
}

body {
  font-family: sans-serif;
}

h2 {
  font-family: $fontEd;
  font-size: 6rem;
  margin-bottom: 2rem;
  @include bpmax(1023) {
    font-size: 2.3rem;
    margin-bottom: 0;
  }

  br {
    @include bp(1024) {
      display: none;
    }
  }
}

p {
  color: #fff;
  font-size: 2rem;
  font-family: $fontCalibri;
  @include bpmax(1023) {
    color: #3d3d3d;
    font-size: 1.5rem;
  }
}
