/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: '';
  content: none; }

q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

@font-face {
  font-family: "Edwardian";
  src: url("http://davidharroch.fr/charlestraiteur/fonts/Edwardian/Edwardian-Script-ITC_15099.ttf"); }

@font-face {
  font-family: "Calibri";
  src: url("http://davidharroch.fr/charlestraiteur/fonts/Calibri/CalibriRegular.ttf"); }

html, body {
  position: relative;
  font-size: calc(16px + 0.5vw);
  scroll-behavior: smooth; }
  @media screen and (min-width: 64em) {
    html, body {
      font-size: .8vw; } }

body {
  font-family: sans-serif; }

h2 {
  font-family: "Edwardian";
  font-size: 6rem;
  margin-bottom: 2rem; }
  @media screen and (max-width: 63.9375em) {
    h2 {
      font-size: 2.3rem;
      margin-bottom: 0; } }
  @media screen and (min-width: 64em) {
    h2 br {
      display: none; } }

p {
  color: #fff;
  font-size: 2rem;
  font-family: "Calibri"; }
  @media screen and (max-width: 63.9375em) {
    p {
      color: #3d3d3d;
      font-size: 1.5rem; } }

.header {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15rem; }
  @media screen and (max-width: 63.9375em) {
    .header {
      height: auto; } }
  .header img {
    width: 15rem; }
    @media screen and (max-width: 63.9375em) {
      .header img {
        width: 5rem; } }

.container {
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: url("./../images/c-traiteur-web2.jpg") no-repeat center top;
  background-size: cover; }
  @media screen and (max-width: 63.9375em) {
    .container {
      flex-direction: column; } }
  .container__child {
    position: relative;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    cursor: pointer; }
    @media screen and (min-width: 64em) {
      .container__child {
        transition: transform .5s ease;
        width: 50%;
        height: auto; } }
    .container__child__text {
      position: relative;
      top: 50%;
      transform: translateY(-50%); }
    .container__child h2 {
      color: #3d3d3d; }
      @media screen and (min-width: 64em) {
        .container__child h2 {
          -webkit-transition: transform 0.5s ease;
          -moz-transition: transform 0.5s ease;
          -ms-transition: transform 0.5s ease;
          -o-transition: transform 0.5s ease;
          transition: transform 0.5s ease;
          -webkit-transform: translate3d(0, 50%, 0);
          transform: translate3d(0, 50%, 0); } }
    .container__child p {
      display: none; }
      @media screen and (min-width: 64em) {
        .container__child p {
          display: block;
          transform: scale(0);
          -webkit-transition: transform 0.5s ease;
          -moz-transition: transform 0.5s ease;
          -ms-transition: transform 0.5s ease;
          -o-transition: transform 0.5s ease;
          transition: transform 0.5s ease; } }
      @media screen and (min-width: 48em) {
        .container__child p br.mobile {
          display: none; } }
      @media screen and (max-width: 63.9375em) {
        .container__child p br.desktop {
          display: none; } }
    @media screen and (min-width: 64em) {
      .container__child:hover {
        overflow: hidden; } }
    .container__child:hover h2 {
      color: #fff; }
      @media screen and (min-width: 64em) {
        .container__child:hover h2 {
          -webkit-transition: transform 0.5s ease;
          -moz-transition: transform 0.5s ease;
          -ms-transition: transform 0.5s ease;
          -o-transition: transform 0.5s ease;
          transition: transform 0.5s ease;
          -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0); } }
    .container__child:hover p {
      color: #fff; }
      @media screen and (min-width: 64em) {
        .container__child:hover p {
          color: #fff;
          transform: scale(1);
          -webkit-transition: transform 0.5s ease;
          -moz-transition: transform 0.5s ease;
          -ms-transition: transform 0.5s ease;
          -o-transition: transform 0.5s ease;
          transition: transform 0.5s ease; } }
  .container .layer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(251, 83, 83, 0);
    width: 100%;
    height: 100%;
    transition: background-color .3s ease; }
    .container .layer:before {
      content: '';
      position: absolute;
      top: 30px;
      right: 30px;
      bottom: 30px;
      left: 30px;
      border: 1px solid #fff;
      transform: scale(0);
      -webkit-transition: transform 0.5s ease;
      -moz-transition: transform 0.5s ease;
      -ms-transition: transform 0.5s ease;
      -o-transition: transform 0.5s ease;
      transition: transform 0.5s ease; }
      @media screen and (max-width: 64em) {
        .container .layer:before {
          top: 10px;
          right: 10px;
          bottom: 10px;
          left: 10px; } }
    .container .layer:hover {
      background-color: rgba(109, 8, 26, 0.7);
      -webkit-transition: background-color 0.5s ease;
      -moz-transition: background-color 0.5s ease;
      -ms-transition: background-color 0.5s ease;
      -o-transition: background-color 0.5s ease;
      transition: background-color 0.5s ease; }
      .container .layer:hover:before {
        transform: scale(1);
        -webkit-transition: transform 0.5s ease;
        -moz-transition: transform 0.5s ease;
        -ms-transition: transform 0.5s ease;
        -o-transition: transform 0.5s ease;
        transition: transform 0.5s ease; }
