.header {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 15rem;
  @include bpmax(1023) {
    height: auto;
  }

  img {
    width: 15rem;
    @include bpmax(1023) {
      width: 5rem;
    }
  }
}

.container {
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: url('./../images/c-traiteur-web2.jpg') no-repeat center top;
  background-size: cover;

  @include bpmax(1023) {
    flex-direction: column;
  }

  &__child {
    position: relative;
    height: 50%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    cursor: pointer;
    @include bp(1024) {
      // border: 1px solid #fff;
      transition: transform .5s ease;
      width: 50%;
      height: auto;
      // -webkit-filter: grayscale(100%);
  	  // filter: grayscale(100%);
    }

    &__text {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }

    h2 {
      color: #3d3d3d;
      @include bp(1024) {
        @include transition(transform, .5s, ease);
        -webkit-transform: translate3d(0,50%,0);
        transform: translate3d(0,50%,0);
      }
    }

    p {
      display: none;
      @include bp(1024) {
        display: block;
        transform: scale(0);
        @include transition(transform, .5s, ease);
      }

      br {
        &.mobile {
          @include bp(768) {
            display: none;
          }
        }

        &.desktop {
          @include bpmax(1023) {
            display: none;
          }
        }
      }
    }

    &:hover {
      @include bp(1024) {
        // transform: scale(1.05);
        // background-color: #fb5353;
        // background-color: #D4AF37;
        overflow: hidden;
      }
      // -webkit-filter: grayscale(0);
	    // filter: grayscale(0);

      h2 {
        color: #fff;
        @include bp(1024) {
          @include transition(transform, .5s, ease);
          -webkit-transform: translate3d(0,0,0);
          transform: translate3d(0,0,0);
        }
      }

      p {
        color: #fff;
        @include bp(1024) {
          color: #fff;
          transform: scale(1);
          @include transition(transform, .5s, ease);
        }
      }
    }
  }

  &__left {
    // background: url('./../images/canapes.jpg') no-repeat center top;
    // background-size: cover;
    // border-right: 1px solid #fff;
    @include bpmax(1023) {
    }
  }

  &__right {
    // background: url('./../images/reception.jpg') no-repeat center top;
    // background-size: cover;
    // border-left: 1px solid #fff;
    @include bpmax(1023) {
    }
  }

  .layer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(251,83,83,0);
    width: 100%;
    height: 100%;
    transition: background-color .3s ease;

    &:before {
      content: '';
      position: absolute;
      top: 30px;
      right: 30px;
      bottom: 30px;
      left: 30px;
      border: 1px solid #fff;
      transform: scale(0);
      @include transition(transform, .5s, ease);
      @include bpmax(1024) {
        top: 10px;
        right: 10px;
        bottom: 10px;
        left: 10px;
      }
    }

    &:hover {
      // background-color: rgba(251,83,83,.7);
      background-color: rgba(109, 8, 26, .7);
      // background-color: rgba(212,175,55,.7);
      @include transition(background-color, .5s, ease);

      &:before {
        transform: scale(1);
        @include transition(transform, .5s, ease);
      }
    }
  }


}
